import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'
import { PagedResults, request, TableQuery, TableState } from '@/edshed-common/api'
import config from '../config'
import { EndpointDetailTypes, EndpointTypes, IdRecord, RecordTypeNames } from './types'

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

axios.defaults.withCredentials = true

export default {
  getServerBaseUri,

  async getOne<TRecordTypeName extends RecordTypeNames> (recordTypeName: TRecordTypeName, id: number)
  : Promise<EndpointDetailTypes extends { [k in TRecordTypeName]: infer TDetailType } ? TDetailType : EndpointTypes[TRecordTypeName]> {
    const endpointInfo = Endpoints[recordTypeName]

    const res = await request('GET', `${endpointInfo.one.uri}/${id}`)

    return res.data[endpointInfo.one.prop] as any
  },

  async getPaged<TRecordTypeName extends RecordTypeNames> (recordTypeName: TRecordTypeName, tableState: TableState, extraParams: Record<string, string>): Promise<PagedResults<EndpointTypes[TRecordTypeName]>> {
    const endpointInfo = Endpoints[recordTypeName]

    const { perPage, page, sort, dir, term } = tableState

    const tableQuery: TableQuery = {
      take: perPage,
      skip: perPage * (page - 1),
      sort,
      dir,
      term
    }

    const res = await request('GET', endpointInfo.list.uri, _.merge(tableQuery, extraParams))

    return res.data[endpointInfo.list.prop] as PagedResults<EndpointTypes[TRecordTypeName]>
  },

  async save<TRecordTypeName extends RecordTypeNames> (recordTypeName: TRecordTypeName, record: Partial<IdRecord>, uploadPercentCallback?: (percent: number) => void) {
    const endpointInfo = Endpoints[recordTypeName]

    const data = { [endpointInfo.one.prop]: record }

    if (record.id) {
      await request('PUT', `${endpointInfo.one.uri}/${record.id}`, data, uploadPercentCallback)
    } else {
      await request('POST', `${endpointInfo.list.uri}`, data, uploadPercentCallback)
    }
  },

  async delete<TRecordTypeName extends RecordTypeNames> (recordTypeName: TRecordTypeName, id: number) {
    const endpointInfo = Endpoints[recordTypeName]

    await request('DELETE', `${endpointInfo.one.uri}/${id}`, null)
  }
}

function getServerBaseUri () {
  return config.serverURI
}

interface EndpointInfo {
  list: {
    uri: string,
    prop: string
  },

  one: {
    uri: string,
    prop: string
  }
}

const Endpoints: Record<RecordTypeNames, EndpointInfo> = {
  curriculum_resources: {
    list: {
      uri: 'resources/resources',
      prop: 'resources'
    },

    one: {
      uri: 'resources/resources',
      prop: 'resource'
    }
  },

  quiz_packs: {
    list: {
      uri: 'quiz/packs',
      prop: 'packs'
    },

    one: {
      uri: 'quiz/packs',
      prop: 'pack'
    }
  },

  quiz_curricula: {
    list: {
      uri: 'quiz/curricula',
      prop: 'curricula'
    },

    one: {
      uri: 'quiz/curricula',
      prop: 'curriculum'
    }
  },

  quiz_curriculum_nodes: {
    list: {
      uri: 'quiz/curriculum_nodes',
      prop: 'nodes'
    },

    one: {
      uri: 'quiz/curriculum_nodes',
      prop: 'node'
    }
  },

  quiz_question_sets: {
    list: {
      uri: 'quiz/question_sets',
      prop: 'sets'
    },

    one: {
      uri: 'quiz/question_sets',
      prop: 'set'
    }
  },

  quiz_questions: {
    list: {
      uri: 'quiz/questions',
      prop: 'questions'
    },

    one: {
      uri: 'quiz/questions',
      prop: 'question'
    }
  },

  lessons: {
    list: {
      uri: 'lesson/lessons',
      prop: 'lessons'
    },

    one: {
      uri: 'lesson/lessons',
      prop: 'lesson'
    }
  },

  lesson_episodes: {
    list: {
      uri: 'lesson/lesson_episodes',
      prop: 'lesson_episodes'
    },

    one: {
      uri: 'lesson/lesson_episodes',
      prop: 'lesson_episode'
    }
  },

  writing_pieces: {
    list: {
      uri: 'writing_pieces/pieces',
      prop: 'writing_pieces'
    },

    one: {
      uri: 'writing_pieces/pieces',
      prop: 'writing_piece'
    }
  },

  spelling_lists: {
    list: {
      uri: 'lists/lookup',
      prop: 'lists'
    },

    one: {
      uri: 'lists/lookup',
      prop: 'list'
    }
  },

  emails: {
    list: {
      uri: 'email',
      prop: 'emails'
    },

    one: {
      uri: 'email',
      prop: 'email'
    }
  }
}
